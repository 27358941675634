import Session from '../domain/session';
import Class from '../domain/class';

export default class GradebookRosterIntegrationStatus {

  /**
   * @param isLinked {booleam}
   * @param isLinkActive {booleam}
   * @param linkedStudentIds {string[]}
   * @param selectedLink {GradebookRoster}
   *
   */
  constructor(
    isLinked,
    isLinkActive,
    linkedStudentIds,
    selectedLink,
  ) {
    this._isLinked = isLinked;
    this._isLinkActive = isLinkActive;
    this._linkedStudentIds = linkedStudentIds;
    this._selectedLink = selectedLink;
  }

  get isLinked (){
    return this._isLinked;
  }

  get isLinkActive () {
    return this._isLinkActive;
  }

  get linkedStudentIds () {
    return this._linkedStudentIds;
  }

  get selectedLink (){
    return this._selectedLink;
  }

}
