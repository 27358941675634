import GradeExportTemplate from '../grade-export-dialog/grade-export-dialog.html';
import Validation from '../../model/util/validation';
import LocationUtil from '../../model/util/location-util';
import GradebookStudentExport from '../../model/domain/gradebook-student-export';
import {MatchState} from '../../model/domain/gradebook-student-export';

export class GradeExportStates {
  static get REQUEST_ACCESS_FROM_ADMIN() {
    return 'request_access_from_admin';
  }

  static get ACCESS_REQUESTED_FROM_ADMIN() {
    return 'access_requested_from_admin';
  }

  static get AUTHENTICATE() {
    return 'authenticate';
  }

  static get AUTH_ERROR() {
    return 'auth_error';
  }

  static get EXPORT_MAIN() {
    return 'export_main';
  }

  static get SELECT_CLASS() {
    return 'select_class';
  }

  static get SELECT_STUDENTS() {
    return 'select_students';
  }

  static get DEFAULT_ERROR() {
    return 'default_error';
  }
}

export default class GradeExportDialogController {
  constructor($mdDialog, $mdToast, $scope, $q, $sce, $location, $window, environment, AnalyticsService,
              AuthService, CacheService, GradebookService, StorageService) {
    'ngInject';

    this.$scope = $scope;
    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$sce = $sce;
    this.$location = $location;
    this.$window = $window;
    this.environment = environment;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {GradebookService} */
    this._gradebookService = GradebookService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this._loading = true;
    this._state = null;
    this._integrationStatus = null;
    this._hasAuthError = !!this._storageService.edlinkError;
    this._defaultError = null;

    this.adminEmail = null;
    this.lmsEmail = null;
    this.isCorrectLmsEmail = false;
    this.invalidEmail = false;
    this.selectedSession = null;
    this.selectedCourse = null;
    this.selectedClass = null;
    this.gradebookStudentExports = null;
    this.isExporting = false;
    this.checkLmsEmailValidity = function(input) {
      this.invalidEmail = !Validation.isValidEmail(this.lmsEmail);
    };

    this._init();
  }

  get integrationStatus() {
    return this._integrationStatus;
  }

  get rosterIntegrationStatus() {
    return this._rosterIntegrationStatus;
  }

  get loading() {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
  }

  get state() {
    return this._state;
  }

  set state(value) {
    this._state = value;
  }

  get requestAccessFromAdmin() {
    return GradeExportStates.REQUEST_ACCESS_FROM_ADMIN;
  }

  get accessRequestedFromAdmin() {
    return GradeExportStates.ACCESS_REQUESTED_FROM_ADMIN;
  }

  get authenticate() {
    return GradeExportStates.AUTHENTICATE;
  }

  get authError() {
    return GradeExportStates.AUTH_ERROR;
  }

  get exportMain() {
    return GradeExportStates.EXPORT_MAIN;
  }

  get selectClass() {
    return GradeExportStates.SELECT_CLASS;
  }

  get selectStudents() {
    return GradeExportStates.SELECT_STUDENTS;
  }

  get hasAuthError() {
    return this._hasAuthError;
  }

  get defaultError() {
    return GradeExportStates.DEFAULT_ERROR;
  }

  get classkickAssignment() {
    return this._classkickAssignment;
  }

  get classkickRoster() {
    return this._classkickRoster;
  }

  get classkickStudents() {
    return this._classkickStudents;
  }

  lmsStudent(lmsId) {
    const lmsStudents = this.lmsStudents || [];
    return lmsStudents.find((student) => student.id === lmsId);
  }

  lmsStudentFromDisplayName(lmsDisplayName) {
    const lmsStudents = this.lmsStudents || [];
    return lmsStudents.find((student) => student.displayName === lmsDisplayName);
  }

  getIndicatorClass(matchState) {
    return matchState === MatchState.MATCHED ? 'green' : 'yellow';
  }

  selectStudent(gradebookStudentExport) {
    if (gradebookStudentExport.matchState === MatchState.MATCHED) {
      gradebookStudentExport.selected = !gradebookStudentExport.selected;
    }
  }

  toggleSelectAll() {
    const selectAll = this.selectAll;
    this.gradebookStudentExports.forEach((student) => {
      if (student.matchState === MatchState.MATCHED) {
        student.selected = selectAll;
      }
    });
  }

  get selectedStudentIds() {
    return this.gradebookStudentExports
      .filter((gradebookStudentExport) => gradebookStudentExport.selected && gradebookStudentExport.lmsStudent && gradebookStudentExport.lmsStudent.id && gradebookStudentExport.id)
      .map((gradebookStudentExport) => gradebookStudentExport.id);
  }

  selectSession() {
    this.selectedCourse = null;
    this.selectedClass = null;
    this.refreshLmsLinks();
  }

  selectCourse() {
    this.selectedClass = null;
    this.refreshLmsLinks();
  }

  matchStudent(gradebookStudentExport) {
    let existingLmsStudent = gradebookStudentExport.lmsStudent;
    let newLmsStudent = this.lmsStudentFromDisplayName(gradebookStudentExport.selectedLmsStudentDisplayName);

    if (!newLmsStudent) {
      this.unlinkStudent(gradebookStudentExport);
      gradebookStudentExport.selected = false;
    } else if (!existingLmsStudent || existingLmsStudent.id !== newLmsStudent.id) {
      this.linkStudent(gradebookStudentExport, newLmsStudent);
      gradebookStudentExport.selected = true;
    }
  }

  linkStudent(gradebookStudentExport, linkLmsStudent) {
    this.loading = true;
    this._analyticsService.gradebookOverviewStudentLinked(this._authService.currentUserId);
    this._gradebookService.linkStudentToGradebook(gradebookStudentExport.id, linkLmsStudent.id)
      .then(() => {
        // Add the old lmsStudent, if it exists, back into the the lmsStudents array
        if (gradebookStudentExport.lmsStudent) {
          this.lmsStudents.push(gradebookStudentExport.lmsStudent);
        }

        // Update the GradebookStudentExport with the new lmsStudent
        gradebookStudentExport.lmsStudent = linkLmsStudent;
        gradebookStudentExport.matchState = MatchState.MATCHED;

        // Remove the newly selected student from the available options
        this.lmsStudents = this.lmsStudents.filter((lmsStudent) =>
          lmsStudent.id !== linkLmsStudent.id
        );

        this.loading = false;
      }).catch((error) => {
      this.loading = false;
    });
  }

  unlinkStudent(gradebookStudentExport) {
    this.loading = true;
    this._gradebookService.removeStudentFromGradebook(gradebookStudentExport.id, gradebookStudentExport.lmsStudent.id)
      .then(() => {
        // Add the old lmsStudent back into the the lmsStudents array
        this.lmsStudents.push(gradebookStudentExport.lmsStudent);

        // Reset the selected email and the associated LMS student
        gradebookStudentExport.lmsStudent = null;
        gradebookStudentExport.selectedLmsStudentDisplayName = null;

        this.loading = false;
      }).catch((error) => {
        this.loading = false;
    });
  }


  getLmsStudent(lmsId) {
    return this.lmsStudents.find((student) => student.id === lmsId);
  }


  _init() {
    if (this._storageService.edlinkCode) {
      this.getIntegrationStatus();
      return this._gradebookService.userIntegrationForGradebook(this._authService.currentUserId, this._storageService.edlinkCode)
        .then(() => {
          this.getIntegrationStatus();
        }).catch((error) => {
          this._state = GradeExportStates.AUTH_ERROR;
          this._storageService.edlinkCode = null;
        });
    } else {
      return this.getIntegrationStatus();
    }
  }

  getIntegrationStatus() {
    return this._gradebookService.gradebookIntegrationStatus(this._authService.currentUserId)
      .then((status) => {
        this._integrationStatus = status;
        if (this.integrationStatus.previousAdminEmail && this.integrationStatus.previousAdminEmail.length) {
          this.adminEmail = this.integrationStatus.previousAdminEmail;
          this.lmsEmail = this.integrationStatus.lmsEmail;
        }
        this._setDialogState();
      }).catch((error) => {
        this._defaultError = error;
        this._state = GradeExportStates.DEFAULT_ERROR;
      });
  }


  _setDialogState() {
    if (!this.integrationStatus.isLinked || !this.integrationStatus.isLinkActive) {
      if (this.integrationStatus.minimumWaitTime === 0) {
        if (this.integrationStatus.numberOfInvites === 0) {
          this.loading = false;
          return this.state = GradeExportStates.REQUEST_ACCESS_FROM_ADMIN;
        } else {
          this.loading = false;
          return this.state = GradeExportStates.AUTHENTICATE;
        }
      } else {
        this.loading = false;
        return this.state = GradeExportStates.ACCESS_REQUESTED_FROM_ADMIN;
      }
    }
    if (this._integrationStatus.isLinked && this.integrationStatus.isLinkActive) {
      this.state = GradeExportStates.EXPORT_MAIN;
      return this._gradebookService.gradebookIntegrationRosterStatus(this._storageService.ckRosterId)
        .then((rosterIntegrationStatus) => {
          this._rosterIntegrationStatus = rosterIntegrationStatus;
          if (rosterIntegrationStatus.selectedLink) {
            this.selectedSession = rosterIntegrationStatus.selectedLink.session;
            this.selectedCourse = rosterIntegrationStatus.selectedLink.course;
            this.selectedClass = rosterIntegrationStatus.selectedLink.class;
          } else {
            this.selectedSession = null;
            this.selectedCourse = null;
            this.selectedClass = null;
          }
          return this.$q.all({
            rosters: this._cacheService.getRostersForUser(),
            assignment: this._cacheService.getAssignment(this._storageService.ckAssignmentId, false),
            students: this._cacheService.getRosterUsers(this._storageService.ckRosterId, false)
          })
            .then(({rosters, assignment, students}) => {
              this._classkickRoster = rosters.get(this._storageService.ckRosterId);
              this._classkickAssignment = assignment;
              this._classkickStudents = students;
              this._initGradebookStudentExport();
            });
        }).catch((error) => {
          this._defaultError = error;
          this._state = GradeExportStates.DEFAULT_ERROR;
        });
    }
  }

  _initGradebookStudentExport() {
    this._loading = true;
    return this._gradebookService.gradebookIntegrationRosterStatusForStudents(this._classkickRoster.id)
      .then((studentsStatus,) => {
        this.gradebookStudentExports = studentsStatus.rosterStudents.map((rosterStudent) => {
          const lmsStudent = studentsStatus.lmsStudents.find((student) => student.id === rosterStudent.lmsId);
          return GradebookStudentExport.from(rosterStudent, lmsStudent);
        });

        // Filter out inactive link students and those already matched in gradebookStudentExports
        this.lmsStudents = studentsStatus.lmsStudents.filter((lmsStudent) =>
          !this.gradebookStudentExports.some((exportedStudent) =>
            exportedStudent.lmsStudent && exportedStudent.lmsStudent.id === lmsStudent.id
          ) && lmsStudent.isLinkActive
        );

        this.loading = false;
      }).catch((error) => {
        this._defaultError = true;
        this._state = GradeExportStates.DEFAULT_ERROR;
      });
  }

  setRequestFromAdminCopy() {
    if (this._hasAuthError) {
      return this.$sce.trustAsHtml('This error may also be because your admin hasn\'t approved access. <br><br>Your school or district\'s administrator needs to allow access. We can request access for you, simply verify your administrator\'s email below and tap Request Access.');
    }
    if (!this._integrationStatus.isLinked) {
      return this.$sce.trustAsHtml('Your school or district’s administrator needs to allow access. We can request access for you, simply verify your administrator’s email below and tap Request Access.');
    }
    if (this._integrationStatus.isLinked) {
      return this.$sce.trustAsHtml('It looks like your school or district’s administrator may have disabled this connection. We can request access for you, simply verify your administrator’s email below and tap Request Access.');
    }
  }

  setEmailValidity(scope) {
    this.invalidEmail = false;
    if (!Validation.isValidEmail(this.adminEmail)) {
      this.invalidEmail = true;
    }
    scope.requestAccessForm.email.$setValidity('pattern', Validation.isValidEmail(this.adminEmail));
  }

  submitAdminRequest(scope) {
    this.invalidEmail = false;
    if (!Validation.isValidEmail(this.adminEmail)) {
      this.invalidEmail = true;
      return scope.requestAccessForm.email.$setValidity('pattern', Validation.isValidEmail(this.adminEmail));
    }
    if (this._authService.currentUserId) {
      this._analyticsService.gradebookAdminAccessInviteSent(
        this._authService.currentUserId,
        this.adminEmail,
        this.integrationStatus !== null ? this.integrationStatus.numberOfInvites : 0);
    }
    return this._gradebookService.gradebookIntegrationInvite(this.adminEmail).then(() => {
      this._gradebookService.gradebookIntegrationStatus(this._authService.currentUserId)
        .then((status) => {
          this._integrationStatus = status;
          this._state = GradeExportStates.ACCESS_REQUESTED_FROM_ADMIN;
        });
    });
  }

  updateLmsEmail() {
    this.invalidEmail = false;
    if (!Validation.isValidEmail(this.lmsEmail)) {
      this.invalidEmail = true;
      return;
    }
    if (this._authService.currentUserId) {
      this._analyticsService.gradebookAuthIssueUnassociatedEmailUpdated(this._authService.currentUserId);
    }
    return this._gradebookService.requestGradebookIntegration(this._authService.currentUserId, this.lmsEmail)
      .then(() => {
        this._gradebookService.gradebookIntegrationStatus(this._authService.currentUserId)
          .then((status) => {
            this._integrationStatus = status;
            this.lmsEmail = status.lmsEmail;
            this._state = GradeExportStates.REQUEST_ACCESS_FROM_ADMIN;
          });
      });
  }

  handleInputEmail(scope) {
    if (this.invalidEmail) {
      this.invalidEmail = false;
      scope.requestAccessForm.email.$setValidity('pattern', true);
    }
  }

  cancel() {
    this._analyticsService.gradebookDismissed();
    this._storageService.edlinkError = null;
    this._storageService.edlinkCode = null;
    this.$mdDialog.cancel();
  }

  close() {
    this._storageService.edlinkError = null;
    this._storageService.edlinkCode = null;
    this.$mdDialog.cancel();
  }

  requestAdminAccess() {
    this.state = GradeExportStates.REQUEST_ACCESS_FROM_ADMIN;
  }

  loginWithEdlink() {
    const state = this.$location.$$path;
    this._storageService.edlinkError = null;
    this._storageService.edlinkCode = null;
    return this.$window.location.href = `https://ed.link/sso/login?client_id=${this.environment.edlinkId}&redirect_uri=${LocationUtil.absRootUrl(this.$location)}&state=${state}&response_type=code`;
  }

  skipCountCopy() {
    const skipCount = this.gradebookStudentExports.filter((gradebookStudentExport) =>
      gradebookStudentExport.lmsStudent == null
    ).length;
    if (skipCount >= 1) {
      return `${skipCount} un-matched student’s grades will be skipped`;
    }
    return null;
  }

  exportGrades() {
    let isExportingWholeClass;
    if (this.classkickStudents === undefined || this.selectedStudentIds === undefined) {
      isExportingWholeClass = false;
    } else {
      isExportingWholeClass = this.classkickStudents.length === this.selectedStudentIds.length;
    }
    this.isExporting = true;
    this._analyticsService.gradebookGradesSubmitted(
      this._authService.currentUserId,
      isExportingWholeClass);
    return this._gradebookService.submitGradesForAssignment(this.classkickRoster.id, this.classkickAssignment.id, this.selectedStudentIds)
      .then(() => {
        this.isExporting = false;
        this.close();
      }).catch((error) => {
        this.isExporting = false;
        this._state = GradeExportStates.DEFAULT_ERROR;
      });
  }

  goToMatchLmsClass(){
    this._loading = true;
    return this._gradebookService.getLinktoGradebookRoster(this._classkickRoster.id)
      .then((rosterLinks) => {
        this.lmsLinks = rosterLinks;
        this.refreshLmsLinks();
        this.state = GradeExportStates.SELECT_CLASS;
        this.loading = false;
      }).catch((error) => {
        this._defaultError = true;
      });
  }

  refreshLmsLinks() {
    const currentSession = this.selectedSession && this.selectedSession.id;
    const currentCourse = this.selectedCourse && this.selectedCourse.id;
    const currentClass = this.selectedClass && this.selectedClass.id;
    this.availableSessions = this.lmsLinks.sessions;
    this.availableCourses = this.lmsLinks.courses.filter((course) => course.sessionId === currentSession);
    this.availableClasses = this.lmsLinks.classes.filter((lmsClass) => lmsClass.courseId === currentCourse);
    this.selectedSession = this.lmsLinks.sessions.find((session) => session.id === currentSession);
    this.selectedCourse = this.lmsLinks.courses.find((course) => course.id === currentCourse);
    this.selectedClass = this.lmsLinks.classes.find((lmsClass) => lmsClass.id === currentClass);
  }

  goBackToExportMain(){
    return this.state = GradeExportStates.EXPORT_MAIN;
  }

  linkToGradebookRoster(){
    this._analyticsService.gradebookOverviewClassLinked(this._authService.currentUserId);
    const gradebookRoster = {
      session_id: this.selectedSession.id,
      course_id: this.selectedCourse.id,
      class_id: this.selectedClass.id
    };
    return this._gradebookService.linkToGradebookRoster(this.classkickRoster.id, gradebookRoster)
      .then(() => {
        this._setDialogState();
      })
      .catch((error) => {
        this._defaultError = true;
      });
  }

  goToMatchStudents(){
    this.state = GradeExportStates.SELECT_STUDENTS;
  }

  static show($mdDialog) {
    return $mdDialog.show({
      controller: GradeExportDialogController,
      template: GradeExportTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      multiple: true,
      locals: {}
    });
  }
}
