'use strict';
import Codec from './codec';
import CourseCodec from './course-codec';
import SessionCodec from './session-codec';
import ClassCodec from './class-codec';
import GradebookRosterIntegrationStatus from '../domain/gradebook-roster-integration-status';
import GradebookRosterCodec from './gradebook-roster-codec';
/**
 * Encodes/decodes Gradebook roster integration status information
 */
export default class GradebookRosterIntegrationStatusCodec extends Codec {
  constructor() {
    super();

    this._sessionCodec = new SessionCodec();
    this._courseCodec = new CourseCodec();
    this._classCodec = new ClassCodec();
    this._gradebookRosterCodec = new GradebookRosterCodec();
  }

  /**
   * @param value {GradebookRosterIntegrationStatus}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      const selectedLink = value.selectedLink && this._gradebookRosterCodec.encode(value.selectedLink) || null;
      return {
        is_linked: value.isLinked,
        is_link_active: value.isLinkActive,
        linked_student_ids: value.linkedStudentIds,
        selected_link: selectedLink,
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {GradebookRosterIntegrationStatus}
   */
  decode(value) {
    if (value) {
      const selectedLink = value.selected_link && this._gradebookRosterCodec.decode(value.selected_link) || null;
      return new GradebookRosterIntegrationStatus(
        value.is_linked,
        value.is_link_active,
        value.linked_student_ids,
        selectedLink
      );
    }

    return null;
  }

}
